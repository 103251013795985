import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { get } from 'lodash'
import GatsbyImage from 'gatsby-image'

import { Layout } from 'src/components/Layout/Layout'
import { Button } from 'src/components/Shared/Button/Button'

import {
  Intro,
  Content,
  IntroHTML,
  CookieLogoSVG,
  MobileCookies,
  CookiePackagingContainer,
  PinkBackground,
  CookiePackagingImageContainer,
  DesktopCookies,
} from './_cookies.styled'
import { useMobileCookiePattern } from '../hooks/useMobileCookiePattern'
import { useDesktopCookiePattern } from '../hooks/useDesktopCookiePattern'
import { useCookiePackaging } from '../hooks/useCookiePackaging'

export const CookiesPage = ({ data }) => {
  const page = get(data, 'prismicCookiesPage')
  const desktopCookiePatternFluid = useDesktopCookiePattern()
  const mobileCookiePatternFluid = useMobileCookiePattern()
  const cookiePackagingFluid = useCookiePackaging()

  return (
    <Layout activePageName="cookies">
      <Helmet title="Cookie Co." />

      <Intro>
        <MobileCookies>
          <GatsbyImage fluid={mobileCookiePatternFluid} alt="" />
        </MobileCookies>

        <DesktopCookies>
          <GatsbyImage fluid={desktopCookiePatternFluid} alt="" />
        </DesktopCookies>

        <Content>
          <CookieLogoSVG />
          <IntroHTML
            dangerouslySetInnerHTML={{
              __html: get(page, 'data.intro_text.html'),
            }}
          />
          <Button
            to={get(page, 'data.button_url.url')}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            {get(page, 'data.button_text')}
          </Button>
        </Content>

        <CookiePackagingContainer>
          <PinkBackground />
          <CookiePackagingImageContainer>
            <GatsbyImage fluid={cookiePackagingFluid} alt="" />
          </CookiePackagingImageContainer>
        </CookiePackagingContainer>
      </Intro>
    </Layout>
  )
}

export default CookiesPage

export const query = graphql`
  query {
    prismicCookiesPage {
      data {
        intro_text {
          html
        }
        button_text
        button_url {
          url
        }
      }
    }
  }
`
