import styled from 'react-emotion'
import { ReactComponent as AssetCookieLogoSVG } from 'src/assets/cookie-logo.svg'

import t from 'src/theme'

export const Intro = styled.section`
  position: relative;
  padding: 100px 30px 0;
  overflow: hidden;
  background-color: ${t.c.lightCream};
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
`

export const CookieLogoSVG = styled(AssetCookieLogoSVG)`
  width: 180px;
  margin-bottom: 24px;

  ${t.mq.m} {
    width: 250px;
    margin-bottom: 30px;
  }
`

export const IntroHTML = styled.div`
  color: ${t.c.darkGray};
  text-align: center;
  font-family: ${t.ff.monoBody};
  margin: 0 auto 24px;
  max-width: 360px;

  ${t.mq.m} {
    max-width: 560px;
    margin: 0 auto 30px;
  }

  & p {
    font-size: 14px;
    line-height: 1.65;

    &:last-child {
      margin-bottom: 0;
    }

    ${t.mq.m} {
      line-height: 2;
    }
  }
`

export const MobileCookies = styled.div`
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  pointer-events: none;

  ${t.mq.ts} {
    top: -90px;
  }

  ${t.mq.s} {
    top: -120px;
  }

  @media (min-width: 600px) {
    top: -150px;
  }

  ${t.mq.m} {
    display: none;
  }
`

export const CookiePackagingContainer = styled.div`
  position: relative;
  padding-bottom: 100px;
`

export const CookiePackagingImageContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
`

export const PinkBackground = styled.div`
  position: absolute;
  background-color: ${t.c.pink};
  left: -30px;
  right: -30px;
  bottom: 0;
  top: calc(50% - 28.5px);
  pointer-events: none;
`

export const DesktopCookies = styled.div`
  display: none;
  position: absolute;
  pointer-events: none;
  max-width: 1920px;
  margin: 0 auto;

  ${t.mq.m} {
    display: block;
    top: -150px;
    left: -90px;
    right: -90px;
  }

  ${t.mq.xx} {
    top: -180px;
    left: 0;
    right: 0;
  }

  @media (min-width: 1920px) {
    top: -270px;
  }
`
