import React from 'react'
import { get } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'

export function useDesktopCookiePattern() {
  const queryData = useStaticQuery(graphql`
    query DesktopCookiePattern {
      file(name: { eq: "cookies-pattern-desktop" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return React.useMemo(() => get(queryData, 'file.childImageSharp.fluid'), [
    queryData,
  ])
}
